<template>
  <div id="contenido-pagina" class="container">
    <div class="alert alert-danger text-center">
      <div class="font-weight-bold">Tu sesión ha expirado</div>
      <div>
        Vuelve a
        <b-link href="https://portal.manusa.com">portal.manusa.com</b-link> para
        autenticarte de nuevo
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SesionExpirada",
};
</script>
